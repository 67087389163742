// @import url('https://fonts.googleapis.com/css?family=Karla');

@font-face {
  font-family: 'GT Walsheim';
  src: url('./assets/fonts/GTWalsheim/GTWalsheim-Medium.woff2') format('woff2'),
      url('./assets/fonts/GTWalsheim/GTWalsheim-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('./assets/fonts/GTWalsheim/GTWalsheim-Black.woff2') format('woff2'),
      url('./assets/fonts/GTWalsheim/GTWalsheim-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('./assets/fonts/GTWalsheim/GTWalsheim-Regular.woff2') format('woff2'),
      url('./assets/fonts/GTWalsheim/GTWalsheim-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim';
  src: url('./assets/fonts/GTWalsheim/GTWalsheim-Bold.woff2') format('woff2'),
      url('./assets/fonts/GTWalsheim/GTWalsheim-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  padding: 0;

  &,
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

html {
  font-family: sans-serif;
  font-size: 62.5%;
}

body {
  margin: 35px 10px 40px 10px;
  font-family: 'GT Walsheim';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // font-family: 'Karla', Helvetica, sans-serif;
  font-size: 1.6rem;
  line-height: 1.5;
  background-color: #121212;
  color: #fff;
}
